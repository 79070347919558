<template>
  <div class="addRoom">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加客房</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表单 -->
    <el-form label-width="80px">
      <el-form-item label="客房名称">
        <el-input v-model="formList.roomName" placeholder="请输入客房名称"></el-input>
      </el-form-item>
      <el-form-item label="所属酒店">
        <el-select v-model="formList.hotelId" placeholder="请选择">
          <el-option
            v-for="item in hotelLists"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="床型" class="col">
        <el-input v-model="formList.bedType" placeholder="请输入床型"></el-input>
      </el-form-item>
      <el-form-item label="面积" class="col">
        <el-input v-model="formList.area" placeholder="请输入面积">
          <template slot="append">平米</template>
        </el-input>
      </el-form-item>
      <el-form-item label="楼层" class="col1">
        <el-input v-model="formList.floor" placeholder="请输入楼层">
          <template slot="append">楼</template>
        </el-input>
      </el-form-item>
      <el-form-item label="人数" class="col1">
        <el-input
          @input="onInput1"
          v-model.number="formList.maxCount"
          placeholder="请输入人数"
        >
          <template slot="append">人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="是否退订">
        <el-radio v-model="radio" label="1">可退订</el-radio>
        <el-radio v-model="radio" label="0">不可退订</el-radio>
      </el-form-item>
      <el-form-item label="是否改期">
        <el-radio v-model="formList.changeInfo" :label="1">是</el-radio>
        <el-radio v-model="formList.changeInfo" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否享受会员权益">
        <el-radio v-model="formList.isUser" :label="1">是</el-radio>
        <el-radio v-model="formList.isUser" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-radio v-model="formList.isShow" :label="1">是</el-radio>
        <el-radio v-model="formList.isShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="万旅网是否显示">
        <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
        <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="销售平台">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0"></div>
        <el-checkbox-group v-model="checked" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in platform" :label="item.value" :key="item.value">{{
            item.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="formList.supplierId" placeholder="请选择">
          <el-option
            v-for="item in supplierOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算价">
        <el-input v-model="formList.settlePrice" placeholder="请输入结算价"></el-input>
      </el-form-item>

      <el-form-item label="游戏ID">
        <el-input
          v-model="formList.gameId"
          placeholder="购票成功后是否参与游戏活动  0 为不参与"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select v-model="formList.sellWay" placeholder="请选择">
          <el-option
            v-for="item in sellWayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所需积分" v-show="formList.sellWay != 1 ? true : false">
        <el-input
          @input="onInput"
          v-model.number="integral"
          type="number"
          placeholder="请输入积分"
        ></el-input>
      </el-form-item>
      <el-form-item label="展示图" class="showImg">
        <send-image type="one" @addImageStr="addImageStr"></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：172*115px</span
        >
      </el-form-item>
      <el-form-item label="轮播图" class="showImg">
        <send-image type="more" @addImageStrs="addImageStrs"></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：345*188px</span
        >
      </el-form-item>
      <el-form-item label="设施设备">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.facility"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="客房简介">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="费用明细">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.expend"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
const platform = [
  { value: 1, name: "小程序" },
  { value: 2, name: "H5" },
  { value: 3, name: "IOS" },
  { value: 4, name: "Android" },
  { value: 5, name: "PC" },
];
// const cityOptions = [1,2,3,4,5];
import { sendFile } from "../../api/sendFile";
import { hotelList, allHotel } from "../../api/hotelList";
import { addRoom } from "../../api/room";
import { supList } from "../../api/supList.js";
import SendImage from "../../components/sendImage.vue";
export default {
  name: "AddRoom",
  components: {
    SendImage,
  },
  data() {
    return {
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      radio: "1",
      roomImg: [],

      hotelLists: [],
      sellWayList: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
      formList: {
        roomName: "",
        hotelId: null,
        bedType: "",
        area: "",
        floor: "",
        maxCount: null,
        showImg: "",
        roomImg: "",
        facility: "",
        remark: "",
        expend: "",
        sellWay: null,
        sellPlace: "",
        changeInfo: 1,
        isShow: 1,
        gameId: 0,
        isUser: 0,
        wlShow: 1,
        isNew:0
      },
      unsubscribe: 1,
      integral: null,
    };
  },
  watch: {
    radio: function () {
      this.unsubscribe = Number(this.radio);
    },
    roomImg: function () {
      this.formList.roomImg = this.roomImg.join(",");
    },
  },
  created() {
    this.getHotelList();
    this.getSupplierList();
    this.init();
  },
  methods: {
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    init() {
      for (let i = 0; i < this.platform.length; i++) {
        this.checkVal.push(this.platform[i].value);
      }
    },
    handleCheckAllChange(val) {
      this.checkVal = [];
      if (val) {
        this.init();
        this.checked = this.checkVal;
        this.formList.sellPlace = "0";
      } else {
        this.checked = [];
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.platform.length;
      console.log(this.checked, "checked");
      this.formList.platform = this.checked.toString();
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)) {
        this.$message({
          type: "error",
          message: "请输入整数!",
        });
        this.integral = "";
        return false;
      }
    },
    async onSave() {
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      if (
        this.formList.sellWay != 1 &&
        (this.integral === null || this.integral === "")
      ) {
        return this.$message({
          message: "请把数据填写完整",
          type: "error",
        });
      }
      const { data } = await addRoom({
        ...this.formList,
        unsubscribe: this.unsubscribe,
        integral: this.integral,
      });
      console.log(data);
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    onInput1(value) {
      if (isNaN(value)) {
        this.$message({
          message: "请输入数字",
          type: "error",
        });
      }
    },
    async getHotelList() {
      const { data } = await allHotel();
      this.hotelLists = data.data;
    },
    addImageStr(image) {
      this.formList.showImg = image;
      console.log(1);
    },
    addImageStrs(image) {
      this.roomImg = image;
      console.log(2);
    },
  },
};
</script>
<style lang="less" scoped>
.addRoom {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    width: 800px;
    margin-top: 50px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }
    .el-form-item__content {
      width: 500px;
    }
    .showImg {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 20px 20px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 2px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 20px;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  .btn {
    width: 200px;
  }
}
</style>
